/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

//ckeditor
import { CKEditor } from "ckeditor4-react";
import { editorConfig, SwalError } from "../../../../utils/constants";

import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import {
  create,
  getAll,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { useDelete } from "../../../../utils/hooks/useDelete";

import { Col, Form, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { requiredInputs } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { IExMember } from "../../../../interfaces/IExMember";
import { validate } from "./validations";
import { IPublication } from "../../../../interfaces/IPublication";
import moment from "moment";
import parse from "html-react-parser";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";

export const ManageExMembers: FC = () => {
  let { staffId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  const { id }: any = useParams();
  const history = useHistory();
  const { deleteIt } = useDelete();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { INVESTIGATORS, EX_MEMBERS } = paths;

  const [isCreate, setIsCreate] = useState(false);
  const [isIpUser, setIsIpUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Group form
  const [name, setName] = useState("");
  const [surname1, setSurname1] = useState("");
  const [surname2, setSurname2] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionVa, setDescriptionVa] = useState("");
  const [orcid, setOrcid] = useState("");
  const [image, setImage] = useState();
  const [message, setMessage] = useState("");
  const [allowedEdit, setAllowedEdit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    checkIfIsEdit();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const checkIfIsEdit = () => {
    const { pathname }: any = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
      loadDefaultData(true);
    } else {
      loadDefaultData(false);
      setIsCreate(false);
    }
  };

  const loadDefaultData = async (isCreate: boolean) => {
    try {
      let investigators = await getAll(INVESTIGATORS, source);

      if (isMountedRef.current) {
        if (!isCreate) {
          loadExMember();
        } else setAllowedEdit(true);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadExMember = async () => {
    try {
      const {
        name,
        surname1,
        surname2,
        orcid,
        image,
        descriptionEs,
        descriptionEn,
        descriptionVa,
      } = await getById(EX_MEMBERS, id, source);
      if (isMountedRef.current) {
        setName(name);
        setSurname1(surname1);
        setSurname2(surname2);
        setOrcid(orcid);
        setImage(image)
        setDescriptionEs(descriptionEs);
        setDescriptionEn(descriptionEn);
        setDescriptionVa(descriptionVa);
        setAllowedEdit(true);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  //----------------
  const handleSubmit = () => {
    const exMember: IExMember = buildExMember();
    const notValid = validate(exMember);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} ex miembro?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(exMember, image);
    });
  };

  const handleConfirmation = (exMember: IExMember, image: any) => {
    let createOrUpdate = isCreate
      ? create(EX_MEMBERS, exMember, source)
      : update(EX_MEMBERS, id, exMember, source);
    createOrUpdate
      .then(async ({ id: exMemberId }) => {
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Ex miembro ${createOrUpdateText} correctamente.`,
        });
	   if (typeof image !== "string") {
			const formData = new FormData();
			formData.append("image", image);
			await saveFile(EX_MEMBERS, exMemberId, "image", formData, source);
		    }

        history.push(`${ROUTES.ExMembers.all}/${exMemberId}`);
        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildExMember = () => ({
    name,
    surname1,
    surname2,
    descriptionEs,
    descriptionEn,
    descriptionVa,
    orcid,
    
  });

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Ex miembro
        </button>
      );
    }
    //if is update && logged user included as IP
    if (!isCreate || isAdmin) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Ex Miembro
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() =>
              deleteIt(EX_MEMBERS, id, ROUTES.ExMembers.all, source)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  //----------------
  //HANDLE SELECTED PUBLICATIONS

  return (
    <div className="centerPage">
      <div className="container-fluid ">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Ex Miembro</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">
          <span>{message}</span>
        </div>
      </div>
      <div className="form-row mt-4">
		 <div className="col-6 d-flex justify-content-center">
				  <FileUploader handleFile={setImage} image={image} />
				</div>
        <div className="col">
          <small>Nombre español*</small>
          <input
            type="text"
            className="form-control input"
            placeholder="Nombre español"
            value={name}
            onChange={({ target: { value } }) => {
              setName(value);
            }}
            disabled={!allowedEdit}
          />
          <small>Nombre inglés*</small>
          <input
            type="text"
            className="form-control mt-4 input"
            placeholder="Nombre inglés"
            value={surname1 ? surname1 : ""}
            onChange={({ target: { value } }) => {
              setSurname1(value);
            }}
            disabled={!allowedEdit}
          />
          <small>Nombre Valenciano*</small>
          <input
            type="text"
            className="form-control mt-4 input"
            placeholder="Nombre inglés"
            value={surname2 ? surname2 : ""}
            onChange={({ target: { value } }) => {
              setSurname2(value);
            }}
            disabled={!allowedEdit}
          />
        </div>
      </div>

      {(isCreate || allowedEdit) && (
        <>
          <div className="form-row mt-4">
            <div className="col">
              <small>Descripción en español*</small>
              <CKEditor
                initData={descriptionEs}
                config={editorConfig}
                onChange={({ editor }: { editor: any }) =>
                  setDescriptionEs(editor.getData())
                }
                readOnly={!allowedEdit}
              />
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <small>Descripción en inglés*</small>
              <CKEditor
                initData={descriptionEn}
                config={editorConfig}
                onChange={({ editor }: { editor: any }) =>
                  setDescriptionEn(editor.getData())
                }
                readOnly={!allowedEdit}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <small>Descripción en valenciano*</small>
              <CKEditor
                initData={descriptionVa}
                config={editorConfig}
                onChange={({ editor }: { editor: any }) =>
                  setDescriptionVa(editor.getData())
                }
                readOnly={!allowedEdit}
              />
            </div>
          </div>
        </>
      )}

      <div className="col">
        <small>Orcid</small>
        <input
          type="text"
          className="form-control"
          placeholder=""
          value={orcid}
          onChange={({ target: { value } }) => {
            setOrcid(value);
          }}
        />
      </div>
    </div>
  );
};
