/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

//ckeditor
import { CKEditor } from "ckeditor4-react";
import { editorConfig, SwalError } from '../../../../utils/constants';

import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import {
  create,
  getAll,
  getByGroup,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { investigatorRoles } from "../../../../utils/roles";
import estrella from "../../../../assets/images/estrella.svg";
import estrellaAzul from "../../../../assets/images/estrellaSolid.svg";

import { Col, Form, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { requiredInputs } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { IGroups } from "../../../../interfaces/IGroups";
import { IPublication } from "../../../../interfaces/IPublication";
import moment from "moment";
import parse from "html-react-parser";

export const ManageGroups: FC = () => {
  let { staffId, isAdmin, userRoleId } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const { id }: any = useParams();
  const history = useHistory();
  const { deleteIt } = useDelete();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const {
    INVESTIGATORS,
    EX_MEMBERS,
    GROUPS,
    PROGRAMS,
    LINES,
    investigatorGROUP,
    publicationsByGROUP,
    groupSelectPUBLICATION,
  } = paths;

  const [isCreate, setIsCreate] = useState(false);
  const [isIpUser, setIsIpUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Group form
  const [nameEs, setNameEs] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [nameVa, setNameVa] = useState("");
  const [ips, setIps] = useState<any[]>([]);
  const [chosenIps, setChosenIps] = useState<any[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [chosenParticipants, setChosenParticipants] = useState<any[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [programs, setPrograms] = useState<any[]>([]);
  const [chosenPrograms, setChosenPrograms] = useState<any[]>([]);
  const [researchLines, setResearchLines] = useState<any[]>([]);
  const [chosenResearchLines, setChosenResearchLines] = useState<any[]>([]);
  const [exMembers, setExMembers] = useState<any[]>([]);
  const [chosenExMembers, setChosenExMembers] = useState<any[]>([]);
  const [image, setImage] = useState();
  const [url, setUrl] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionVa, setDescriptionVa] = useState("");
  const [pastMembers, setPastMembers] = useState("");
  const [readThesis, setReadThesis] = useState("");
  const [publications, setPublications] = useState<IPublication[]>([]);
  const [selectedPublications, setSelectedPublications] = useState<
    IPublication[]
  >([]);
  const [displayPublications, setDisplayPublications] = useState<
    IPublication[]
  >([]);
  // import { validate } from './validations';

  const [message, setMessage] = useState("");

  const [allowedEdit, setAllowedEdit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    checkIfIsEdit();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const checkIfIsEdit = () => {
    const { pathname }: any = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
      loadDefaultData(true);
    } else {
      loadDefaultData(false);
      setIsCreate(false);
    }
  };

  const loadDefaultData = async (isCreate: boolean) => {
    try {
      let investigators = await getAll(INVESTIGATORS, source);
      let programs = await getAll(PROGRAMS, source);
      let researchLines = await getAll(LINES, source);
      let exMembers = await getAll(EX_MEMBERS, source);
      if (!isCreate) {
        let publications = await getByGroup(publicationsByGROUP, id, source);
        setPublications(publications);
        reorderPublications(publications);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setTimeout(() => filterAdmins(investigators), 250);
        setPrograms(programs);
        setResearchLines(researchLines);
        setExMembers(exMembers);
        if (!isCreate) {
          loadGroup(programs, researchLines, exMembers);
        } else setAllowedEdit(true);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterAdmins = (investigators: any[]) => {
    let newinvestigators = investigators.filter(
      (u) =>
        u.email !== "admin@admin.com" &&
        u.email !== "sombradoble@sombradoble.es"
    );
    filterInvestigators(newinvestigators);
    filterParticipants(newinvestigators);
  };

  const filterInvestigators = (investigators: any) => {
    const ipInvestigators = investigators?.filter(
      ({ investigatorRole, investigationGroup }: any) =>
        investigatorRole?.id === investigatorRoles.ip && !investigationGroup
    );
    setIps(ipInvestigators);
  };

  const filterParticipants = (investigators: any) => {
    const ipParticipans = investigators?.filter(
      ({ investigatorRole, investigationGroup }: any) =>
        investigatorRole?.id !== investigatorRoles.ip && !investigationGroup
    );
    setParticipants(ipParticipans);
  };

  const loadGroup = async (
    programs: any[],
    researchLines: any[],
    exMembers: any[]
  ) => {
    try {
      const {
        nameEs,
        nameEn,
        nameVa,
        programs,
        researchLines,
        exMembers,
        staff,
        investigators,
        descriptionEs,
        descriptionEn,
        descriptionVa,
        pastMembers,
        readThesis,
        image,
        url,
      } = await getById(GROUPS, id, source);
      const ips = staff?.filter(
        (staff: any) => staff.investigatorRole?.name === "IP"
      );
      const participants = staff?.filter(
        (staff: any) => staff.investigatorRole?.name !== "IP"
      );

      if (isMountedRef.current) {
        setNameEs(nameEs);
        setNameEn(nameEn);
        setNameVa(nameVa);
        setMembers(members);
        setDescriptionEs(descriptionEs);
        setDescriptionEn(descriptionEn);
        setPastMembers(pastMembers);
        setReadThesis(readThesis);
        setDescriptionVa(descriptionVa);
        setAllowedEdit(true);
        setImage(image);
        setUrl(url);
        setChosenIps(ips);
        setChosenParticipants(participants);
        setChosenResearchLines(researchLines);
        newResearchLines(researchLines, researchLines);
        setChosenExMembers(exMembers || []);
        newExMembers(exMembers, exMembers);
        setChosenPrograms(programs);
        newPrograms(programs, programs);
        filterMembers(investigators);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };


  

  const newPrograms = (u: any[], programs: any[]) => {
    // eslint-disable-next-line array-callback-return
    u.map((r: any) => {
      setPrograms((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
      );
    });
  };
  const newResearchLines = (u: any[], researchLines: any[]) => {
    // eslint-disable-next-line array-callback-return
    u.map((r: any) => {
      setResearchLines((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
      );
    });
  };

  const newExMembers = (u: any[], exMembers: any[]) => {
    // eslint-disable-next-line array-callback-return
    u.map((r: any) => {
      setExMembers((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
      );
    });
  };

  const filterMembers = (members: any[]) => {
    const ipInvestigators = members.filter(
      ({ investigatorRole }: any) =>
        investigatorRole?.id === investigatorRoles.ip
    );
    setChosenIps(ipInvestigators);

    ipInvestigators?.some(({ id }) => id === staffId) && setIsIpUser(true);

    const participants = members.filter(
      ({ investigatorRole }: any) =>
        investigatorRole?.id !== investigatorRoles.ip
    );
    setChosenParticipants(participants);
  };

  //----------------

  //HANDLE PUBLICATIONS

  const reorderPublications = (publications: any[]) => {

    let newPublications: any[] = [];
    publications.map(
      ({
        id,
        title,
        year,
        journal,
        url,
        authors,
        volPag,
        staffId,
        investigationGroupPublications,
        titleChapter,
      }: // eslint-disable-next-line array-callback-return
      any) => {
        newPublications.push({
          id,
          title,
          year,
          journal,
          url,
          authors,
          volPag,
          staffId,
          titleChapter,
          selected: investigationGroupPublications[0]?.selected,
        });
      }
    );
    return filterSelectedPublications(newPublications);
  };

  const filterSelectedPublications = (publications: IPublication[]) => {
   
    const selectedPublications = publications.filter(
      ({ selected }: IPublication) => selected === true
    );

    setSelectedPublications(selectedPublications);
    const displayPublications = publications.filter(
      ({ selected }: IPublication) => selected === false
    );
    setDisplayPublications(displayPublications);
  };

  //----------------

  // HANDLE INVESTIGATORS
  const addInvestigator = (ip: any, investigator: string) => {
    ip = JSON.parse(ip);

    investigator === "IP"
      ? //add chosenIp
        setChosenIps((state) =>
          produce(state, (drafState) => {
            drafState.push(ip);
          })
        )
      : //add chosenParticipant
        setChosenParticipants((state) =>
          produce(state, (drafState) => {
            drafState.push(ip);
          })
        );

    //remove ip from list
    setIps((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
    );

    //remove participant from list
    setParticipants((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
    );
  };

  const removeInvestigator = (ip: any, investigator: string) => {
    if (isIpUser || isCreate || isAdmin) {
      investigator === "IP"
        ? // remove chosenIp
          setChosenIps((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== ip.id)
            )
          )
        : // remove chosenParticipan
          setChosenParticipants((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== ip.id)
            )
          );

      investigator === "IP"
        ? // add ip to list
          setIps((state) =>
            produce(state, (drafState) => {
              drafState.push(ip);
            })
          )
        : // add participant to list
          setParticipants((state) =>
            produce(state, (drafState) => {
              drafState.push(ip);
            })
          );
    }
  };

  //----------------

  // HANDLE PROGRAMS
  const addProgram = (program: any) => {
    program = JSON.parse(program);
    setChosenPrograms((state) =>
      produce(state, (drafState) => {
        drafState.push(program);
      })
    );
    setPrograms((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== program.id)
      )
    );
  };

  const removeProgram = (program: any) => {
    //remove program from list
    setChosenPrograms((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== program.id)
      )
    );
    // add program to list
    setPrograms((state) =>
      produce(state, (drafState) => {
        drafState.push(program);
      })
    );
  };

  // HANDLE Ex members
  const addExMember = (exMember: any) => {
    exMember = JSON.parse(exMember);
   setChosenExMembers((state) =>
     produce(state || [], (drafState) => {
       drafState.push(exMember);
     })
   );

    setExMembers((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== exMember.id)
      )
    );
  }; 
  const removeExMember = (exMember: any) => {
    //remove line from list
    setChosenExMembers((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== exMember.id)
      )
    );
    // add line to list
    setExMembers((state) =>
      produce(state, (drafState) => {
        drafState.push(exMember);
      })
    );
  };

  // HANDLE Lines
  const addResearchLine = (researchLine: any) => {
    researchLine = JSON.parse(researchLine);
    setChosenResearchLines((state) =>
      produce(state, (drafState) => {
        drafState.push(researchLine);
      })
    );
    setResearchLines((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== researchLine.id)
      )
    );
  };

  const removeResearchLine = (researchLine: any) => {
    //remove line from list
    setChosenResearchLines((state) =>
      produce(state, (drafState) =>
        drafState.filter(({ id }) => id !== researchLine.id)
      )
    );
    // add line to list
    setResearchLines((state) =>
      produce(state, (drafState) => {
        drafState.push(researchLine);
      })
    );
  };

  //----------------
  const handleSubmit = () => {
    const programIds = selectProgramsIds();
    const researchLineIds = selectResearchLinesIds();
    const exMemberIds = selectExMembersIds();
    const group: IGroups = buildGroup(programIds, researchLineIds, exMemberIds);
    const staffIds = selectInvestigatorsIds();

    if (
      !nameEs ||
      !nameEn ||
      !nameVa ||
      !descriptionEs ||
      !descriptionEn ||
      !descriptionVa ||
      !chosenIps ||
      !chosenPrograms
    ) {
      setMessage(requiredInputs);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} grupo de investigación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(group, staffIds, image);
    });
  };

  const handleConfirmation = (
    group: IGroups,
    staffIds: number[],
    image: any
  ) => {
    let createOrUpdate = isCreate
      ? create(GROUPS, group, source)
      : update(GROUPS, id, group, source);
    createOrUpdate
      .then(async ({ id: groupId }) => {
        create(`${investigatorGROUP}/${groupId}`, { staffIds }, source);
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Grupo de Investigación ${createOrUpdateText} correctamente.`,
        });
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(GROUPS, groupId, "image", formData, source);
        }
        history.push(`${ROUTES.Groups.all}/${groupId}`);
        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const selectProgramsIds = () => {
    let programIds: number[] = [];
    chosenPrograms.map(({ id }: any) => programIds.push(id));

    return programIds;
  };
  const selectResearchLinesIds = () => {
    let researchLineIds: number[] = [];
    chosenResearchLines.map(({ id }: any) => researchLineIds.push(id));
    return researchLineIds;
  };

  const selectExMembersIds = () => {
    let exMemberIds: number[] = [];
    chosenExMembers.map(({ id }: any) => exMemberIds.push(id));
    return exMemberIds;
  };
  const selectInvestigatorsIds = () => {
    let staffIds: number[] = [];
    let members = [...chosenIps, ...chosenParticipants];
    members.map(({ id }: any) => staffIds.push(id));

    return staffIds;
  };

  const buildGroup = (
    programIds: number[],
    researchLineIds: number[],
    exMemberIds: number[]
  ) => ({
    nameEs,
    nameEn,
    nameVa,
    descriptionEs,
    descriptionEn,
    descriptionVa,
    readThesis,
    pastMembers,
    programIds,
    researchLineIds,
    exMemberIds,
    url,
  });

  //----------------

  //PUBLICATIONS

  const searchBar = (value: any) => {
    setDisplayPublications(
      displayPublications.filter(({ title }: any) =>
        title.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayPublications(displayPublications);
  };

  const changeIcon = (isSelected: any) => {
    return !isSelected ? estrella : estrellaAzul;
  };

  const changeNotSelected = (index: number) => {
    if (displayPublications[index!].selected === false) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = true;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (displayPublications[index!].selected === true) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = false;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  const changeSelected = (index: number) => {
    if (selectedPublications[index!].selected === false) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = true;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (selectedPublications[index!].selected === true) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = false;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  //----------------
  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Grupo de Investigación
        </button>
      );
    }
    //if is update && logged user included as IP
    if ((!isCreate && chosenIps?.some(({ id }) => id === staffId)) || isAdmin) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Grupo de Investigación
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(GROUPS, id, ROUTES.Groups.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  //----------------
  //HANDLE SELECTED PUBLICATIONS

  const handleSubmitPublications = () => {
    const newPublications = buildSelectedPublications();

    const count = countSelected(newPublications);
    if (count > 10) {
      Swal.fire({
        icon: "error",
        text: "Se pueden seleccionar un máximo de 10 publicaciones",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      create(
        `${groupSelectPUBLICATION}/${id}/publication/`,
        newPublications,
        source
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            text: `Selección actualizada correctamente.`,
          });
          loadDefaultData(false);
          history.push(`${ROUTES.Groups.all}/${id}`);
          isMountedRef.current && setIsCreate(false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: SwalError,
          });
        });
    }
  };

  const buildSelectedPublications = () => {
    let newSelectedPublications: any[] = [];
    let completePublications = [
      ...selectedPublications,
      ...displayPublications,
    ];
    completePublications.map(({ id: publicationId, selected }: any) =>
      newSelectedPublications.push({
        investigationGroupId: +id,
        publicationId,
        selected,
      })
    );
    return newSelectedPublications;
  };

  const countSelected = (totalPublications: any[]) => {
    const count = totalPublications.filter(
      ({ selected }) => selected === true
    ).length;
    return count;
  };

  return (
    <div className="centerPage">
      {!isAdmin && userRoleId !== 3 ? (
        <>Usted no tiene acceso</>
      ) : (
        <>
          {" "}
          <div className="container-fluid ">
            <div className="row">
              <div className="col d-flex justify-content-between">
                <div>
                  <h3>Grupo de investigación</h3>
                </div>
                <div>{editDeleteButtons()}</div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center text-danger">
              <span>{message}</span>
            </div>
          </div>
          <div className="form-row mt-2  d-flex flex-start">
            <div className="col-6 d-flex justify-content-center">
              <FileUploader handleFile={setImage} image={image} />
            </div>
            <div className="col-6  d-flex flex-column  justify-content-center">
              <small>Nombre español*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre español"
                value={nameEs}
                onChange={({ target: { value } }) => {
                  setNameEs(value);
                }}
                disabled={!allowedEdit}
              />
              <small>Nombre inglés*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre inglés"
                value={nameEn ? nameEn : ""}
                onChange={({ target: { value } }) => {
                  setNameEn(value);
                }}
                disabled={!allowedEdit}
              />
              <small>Nombre valenciano*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre Valenciano"
                value={nameVa}
                onChange={({ target: { value } }) => {
                  setNameVa(value);
                }}
                disabled={!allowedEdit}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <small>Seleccionar investigador/es principal/es</small>
              <select
                className="form-control input"
                value={""}
                onChange={({ target: { value } }) => {
                  addInvestigator(value, "IP");
                }}
                disabled={!allowedEdit}
              >
                <option value={""}>Investigadores principales</option>
                {ips?.map((i: any) => (
                  <option key={i.id} value={JSON.stringify(i)}>
                    {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                  </option>
                ))}
              </select>
              {chosenIps?.map((ip: any) => (
                <span
                  key={ip.id}
                  style={{ cursor: "pointer" }}
                  className="badge badge-info badge-blue-color mr-1 mt-3  "
                  onClick={() => removeInvestigator(ip, "IP")}
                >
                  {`${ip.name} ${ip.surname1} ${
                    ip.surname2 ? ip.surname2 : ""
                  }`}{" "}
                  X
                </span>
              ))}
            </div>
            <div className="col">
              <small>Seleccionar investigadores participantes</small>
              <select
                className="form-control input"
                value={""}
                onChange={({ target: { value } }) => {
                  addInvestigator(value, "PARTICIPANT");
                }}
                disabled={!allowedEdit}
              >
                <option value={""}>Investigadores participantes*</option>
                {participants?.map((i: any) => (
                  <option key={i.id} value={JSON.stringify(i)}>
                    {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                  </option>
                ))}
              </select>
              {chosenParticipants?.map((participant: any) => (
                <span
                  key={participant.id}
                  style={{ cursor: "pointer" }}
                  className="badge badge-info badge-blue-color mr-1 mt-3  "
                  onClick={() => removeInvestigator(participant, "PARTICIPANT")}
                >
                  {`${participant.name} ${participant.surname1} ${
                    participant.surname2 ? participant.surname2 : ""
                  }`}
                  X
                </span>
              ))}
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <small>Seleccionar programas*</small>
              <select
                className="form-control input"
                value={""}
                onChange={({ target: { value } }) => {
                  addProgram(value);
                }}
                disabled={!allowedEdit}
              >
                <option value={""}>Programas</option>
                {programs?.map((i: any) => (
                  <option key={i.id} value={JSON.stringify(i)}>
                    {`${i.nameEs}`}
                  </option>
                ))}
              </select>
              {chosenPrograms?.map((program: any) => (
                <span
                  key={program.id}
                  style={{ cursor: "pointer" }}
                  className="badge badge-info badge-blue-color mr-1 mt-3  "
                  onClick={() => removeProgram(program)}
                >
                  {`${program.nameEs}`} X
                </span>
              ))}
            </div>
            <div className="col">
              <small>Web del grupo de investigación</small>
              <input
                type="text"
                className="form-control"
                placeholder="Url"
                value={url ? url : ""}
                onChange={({ target: { value } }) => {
                  setUrl(value);
                }}
                disabled={!allowedEdit}
              />
            </div>
          </div>
          <div className="">
            <small>Seleccionar Acciones transversales</small>
            <select
              className="form-control input"
              value={""}
              onChange={({ target: { value } }) => {
                addResearchLine(value);
              }}
              disabled={!allowedEdit}
            >
              <option value={""}>Acciones transversales</option>
              {researchLines?.map((i: any) => (
                <option key={i.id} value={JSON.stringify(i)}>
                  {`${i.nameEs}`}
                </option>
              ))}
            </select>
            {chosenResearchLines?.map((researchLine: any) => (
              <span
                key={researchLine.id}
                style={{ cursor: "pointer" }}
                className="badge badge-info badge-blue-color mr-1 mt-3  "
                onClick={() => removeResearchLine(researchLine)}
              >
                {`${researchLine.nameEs}`} X
              </span>
            ))}
          </div>{" "}
          <div className="">
            <small>Seleccionar Research Consulting Scientist</small>
            <select
              className="form-control input"
              value={""}
              onChange={({ target: { value } }) => {
                addExMember(value);
              }}
              disabled={!allowedEdit}
            >
              <option value={""}>Ex Miembros</option>
              {exMembers?.map((i: any) => (
                <option key={i.id} value={JSON.stringify(i)}>
                  {`${i.name}`} {`${i.surname1}`} {`${i.surname2}`}
                </option>
              ))}
            </select>
            {chosenExMembers?.map((exMember: any) => (
              <span
                key={exMember.id}
                style={{ cursor: "pointer" }}
                className="badge badge-info badge-blue-color mr-1 mt-3  "
                onClick={() => removeExMember(exMember)}
              >
                {`${exMember.name} ${exMember.surname1} ${exMember.surname2}`} X
              </span>
            ))}
          </div>{" "}
          {(isCreate || allowedEdit) && (
            <>
              <div className="form-row mt-4">
                <div className="col">
                  <small>Descripción en español*</small>
                  <CKEditor
                    initData={descriptionEs}
                    config={editorConfig}
                    onChange={({ editor }: { editor: any }) =>
                      setDescriptionEs(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="col">
                  <small>Descripción en inglés*</small>
                  <CKEditor
                    initData={descriptionEn}
                    config={editorConfig}
                    onChange={({ editor }: { editor: any }) =>
                      setDescriptionEn(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>
              <div className="form-row mt-4">
                <div className="col">
                  <small>Descripción en Valenciano*</small>
                  <CKEditor
                    initData={descriptionVa}
                    config={editorConfig}
                    onChange={({ editor }: { editor: any }) =>
                      setDescriptionVa(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>
            </>
          )}
          {(isCreate || allowedEdit) && (
            <>
              <div className="form-row mt-4">
                <div className="col">
                  <small>Miembros pasados</small>
                  <CKEditor
                    initData={pastMembers}
                    config={editorConfig}
                    onChange={({ editor }: { editor: any }) =>
                      setPastMembers(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="col">
                  <small>Tesis leidas</small>
                  <CKEditor
                    initData={readThesis}
                    config={editorConfig}
                    onChange={({ editor }: { editor: any }) =>
                      setReadThesis(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>
            </>
          )}
          {!isCreate && (
            <div className="row mt-4">
              <div className="col-12 ">
                <div className="col d-flex justify-content-between">
                  <h5>Publicaciones seleccionadas</h5>
                </div>
                <table className="table table-hover mt-4">
                  <tbody>
                    {selectedPublications?.map(
                      (
                        { id, title, authors, year, selected }: IPublication,
                        index
                      ) => (
                        <tr key={index} className="pointer">
                          <td>
                            <div className="d-flex justify-content-between">
                              <div>
                                <h6>{parse(title)}</h6>
                                <span>
                                  {authors} {","} {year}
                                </span>
                              </div>

                              <img
                                style={{ width: "2%" }}
                                src={changeIcon(selected)}
                                alt=""
                                onClick={() => changeSelected(index)}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border mt-5" role="status" />
                  </div>
                )}

                {!selectedPublications.length && !isLoading && (
                  <div className="d-flex justify-content-center mb-3">
                    <h5>-No hay publicaciones seleccionadas-</h5>
                  </div>
                )}
              </div>
            </div>
          )}
          {!isCreate && (
            <div className="row mt-4">
              <Form.Group as={Col}>
                <InputGroup className="shadow">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="inputGroup ">
                      <FontAwesomeIcon color="#0073B5" icon={faSearch} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Buscar..."
                    className="form-control"
                    onChange={({ target: { value } }) => searchBar(value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          )}
          {!isCreate && (
            <div className="row mt-3">
              <div className="col-12 ">
                <h5>Publicaciones</h5>
                <table className="table table-hover">
                  <tbody>
                    {displayPublications?.map(
                      (
                        {
                          title,
                          authors,
                          year,
                          selected,
                          titleChapter,
                        }: IPublication,
                        index
                      ) => (
                        <tr key={index} className="pointer">
                          <td>
                            {titleChapter && <h6>{parse(titleChapter)}</h6>}
                            <div className="d-flex justify-content-between">
                              {titleChapter ? (
                                <h6 className="text-secondary">
                                  {parse(title)}
                                </h6>
                              ) : (
                                <h6>{parse(title)}</h6>
                              )}
                              <img
                                style={{ width: "2%" }}
                                src={changeIcon(selected)}
                                alt=""
                                onClick={() => changeNotSelected(index)}
                              />
                            </div>
                            <p>
                              {authors} {","} {year}
                            </p>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border mt-5" role="status" />
                  </div>
                )}

                {!displayPublications.length && !isLoading && (
                  <div className="d-flex justify-content-center mb-3">
                    <h5>-No hay publicaciones-</h5>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
