import React, { FC, useEffect, useState } from 'react';

//css
import './Login.css';

//middleware
import { useDispatch } from 'react-redux';

//images
//TODO CONSEGUIR LOGO EN BLANCO

import logoi2 from '../../../assets/images/logo.png';

//aux
import { SetUserAction } from '../../../redux/actions';
import { userRoles } from '../../../utils/roles';
import { login } from '../../../utils/requests/dynamicReq';
import { verify } from 'jsonwebtoken';
import { jwtPassword } from '../../../utils/constants';

const Login: FC = () => {
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {}, []);

	const handleSubmit = async () => {
		setLoading(true);
		login(username, password)
			.then(({ token }) => {
				//prettier-ignore
				const {userId,staffId,
        userRoleId, investigatorRoleId,
        name, surname1, surname2,
        username, email, phone}: any = verify(token, jwtPassword);
				localStorage.setItem('token', token);
				setLoading(false);
				dispatch(
					SetUserAction({
						token,
						userId,
						staffId,
						userRoleId,
						investigatorRoleId,
						isAdmin: userRoleId === userRoles.admin ? true : false,
						name,
						surname1,
						surname2,
						username,
						email,
						phone
					})
				);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				setMessage('Nombre de usuario o contraseña incorrecta.');
				setTimeout(() => {
					setMessage('');
					setPassword('');
				}, 3000);
			});
	};

	const disabled = !username || !password;

	return (
		<>
			<div className="loginContainer">
				<div
					className="cardLogin"
					onKeyPress={({ key }) => {
						if (!disabled && key === 'Enter') {
							setMessage('');
							handleSubmit();
						}
					}}
				>
					<img className="loginLogo" src={logoi2} alt="" width={250} />

					<div className="form-group">
						<input
							className="form-control inputLogin mt-4"
							type="text"
							placeholder="Usuario"
							value={username}
							onChange={({ target: { value } }) => {
								setMessage('');
								setUsername(value);
							}}
						/>
					</div>

					<div className="form-group">
						<input
							className="form-control inputLogin"
							type="password"
							placeholder="Contraseña"
							value={password}
							onChange={({ target: { value } }) => {
								setMessage('');
								setPassword(value);
							}}
						/>
					</div>
					{message && <span style={{ color: 'white' }}>{message}</span>}
					<button
						style={{
							cursor: disabled ? 'not-allowed' : ''
						}}
						className="loginButton mt-4"
						disabled={disabled || Boolean(message)}
						onClick={handleSubmit}
					>
						{!loading ? (
							'Login'
						) : (
							<span className="spinner-border spinner-border-sm" />
						)}
					</button>
				</div>
				<div className="mt-3">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.i2sysbio.es/"
					>
						<strong className="login-backBtn">
							Volver a la web del instituto
						</strong>
					</a>
				</div>
			</div>
		</>
	);
};

export default Login;
