import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//datepicker

import "react-datepicker/dist/react-datepicker.css";

//middelware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import Axios from "axios";
import { editorConfig, SwalError } from "../../../../utils/constants";

import {
  create,
  getAll,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import Swal from "sweetalert2";
import { paths } from "../../../../utils/paths";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { PdfUploader } from "../../../../components/FileUploader/PdfUploader";
import { validate } from "./validations";
// import { validate } from './validations';

export const ManageIntranet = () => {
  const { INTRANET } = paths;
  const { INTRANETFOLDER } = paths;
  const { id } = useParams();
  const { deleteIt } = useDelete();

  let { staffId, isAdmin, userId, userRoleId } = useSelector(
    ({ user }) => user
  );

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);

  const [type, setType] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [intranetFolderId, setIntranetFolderId] = useState(null);
  const [folders, setAllFolders] = useState([]);

  const [creatorId, setCreatorId] = useState(null);
  const [image, setImage] = useState();
  const [document, setDocument] = useState("");
  const [message, setMessage] = useState("");
  const [allowedEdit, setAllowedEdit] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadData();
    loadAllFolders();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAllFolders = async () => {
    try {
      let response = await getAll(INTRANETFOLDER, id, source);
      isMountedRef.current && setAllFolders(response);
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      let response = await getById(INTRANET, id, source);
      isMountedRef.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  // prettier-ignore
  const buildObjToLoad = (response) => {
    const {
      title,
      type,
      subcategory,
      image,
      tags,
      document,
      intranetFolderId,
    } = response;
    const {user: { id: userId }} = response;


  
    setTitle(title);
    setType(type)
    setSubCategory(subcategory)
    setCreatorId(userId);
    setImage(image);
    setTags(tags);
    setIntranetFolderId(intranetFolderId)
  
    setDocument(document)
    


	if (isMountedRef.current) 
	setAllowedEdit(true);
  };

  const handleSubmit = () => {
    const intranet = build();
    const notValid = validate(intranet);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (!type) {
      setMessage("Seleccione una tipo de archivo");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (type === "image" && !image) {
      setMessage("Seleccione una imagen");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    if (type === "document" && !document) {
      setMessage("Seleccione un archivo document");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
        if (!intranetFolderId) {
          setMessage("Seleccione una carpeta");
          setTimeout(() => setMessage(""), 4000);
          return;
        }

    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} recurso?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(intranet);
    });
  };

  const handleConfirmation = (news) => {
    let createOrUpdate = isCreate
      ? create(INTRANET, news, source)
      : update(INTRANET, id, news, source);
    createOrUpdate
      .then(async ({ id }) => {
        Swal.fire({
          icon: "success",
          text: `Recurso ${isCreate ? "creado" : "editado"} correctamente.`,
        });
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(INTRANET, id, "image", formData, source);
        }
        if (typeof document !== "string") {
          const formData = new FormData();
          formData.append("document", document);
          await saveFile(INTRANET, id, "document", formData, source);
        }

        history.push(`${ROUTES.Intranet.all}`);
        setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = () => ({
    userId: creatorId ? creatorId : userId,
    title,
    type,
    subcategory,
    tags,
    intranetFolderId,
  });
  // eslint-disable-next-line no-unused-expressions

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Recurso
        </button>
      );
    }
    if ((!isCreate && userId === creatorId) || isAdmin) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Recurso
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(INTRANET, id, ROUTES.Intranet.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  return (
    <div className="centerPage">
      {(userRoleId !== 4) ? (
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <div>
                <h3>Intranet </h3>
              </div>
              <div>{editDeleteButtons()}</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center text-danger">
              <span>{message}</span>
            </div>
          </div>
          {type === "image" ? (
            <div className="form-row mt-2">
              <div className="col-6 d-flex  justify-content-center">
                <FileUploader handleFile={setImage} image={image} />
              </div>
            </div>
          ) : type === "document" ? (
            <div className="form-row mt-2">
              <div className="col-6  justify-content-center">
                <PdfUploader handleFile={setDocument} document={document} />
              </div>
            </div>
          ) : (
            <>Seleccione un tipo de archivo</>
          )}

          <div className="form-row mt-4">
            <div className="col-6">
              <small>*Tipo de archivo</small>
              <select
                className="form-control input"
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                }}
              >
                <option value="">Seleccione un tipo de archivo</option>
                <option value="image">Imagen</option>
                <option value="document">Documento</option>
              </select>
            </div>
          </div>
          <div className="form-row mt-2">
            <div className="col">
              <small>*Título/Title</small>
              <input
                type="text"
                className="form-control"
                placeholder="*Título español"
                value={title}
                onChange={({ target: { value } }) => {
                  setTitle(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <small>Etiquetas</small>
              <input
                type="text"
                className="form-control"
                placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
                value={tags}
                onChange={({ target: { value } }) => {
                  setTags(value);
                }}
              />
            </div>
            <div className="col">
              <small>Etiquetas</small>
              <input
                type="text"
                className="form-control"
                placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
                value={tags}
                onChange={({ target: { value } }) => {
                  setTags(value);
                }}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <small>Carpeta contenedora</small>
            <select
              className="form-control input"
              value={intranetFolderId}
              onChange={({ target: { value } }) => {
                setIntranetFolderId(Number(value));
              }}
            >
              <option value="">Seleccione una carpeta contenedora</option>
              {folders.map((f) => (
                <option value={f.id} key={f.id}>
                  {f.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : <></>}
    </div>
  );
};
