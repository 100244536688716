//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProject } from "../views/Logged/Projects/ManageProjects/ManageProject";
import { Projects } from "../views/Logged/Projects/Projects";
import { TeachingResources } from "../views/Logged/TeachingResource/TeachingResource";
import { ManageTeachingResources } from "../views/Logged/TeachingResource/ManageTeachingResource/ManageTeachingResource";
import { ResearchLines } from "../views/Logged/ResearchLines/ResearchLines";
import { ManageLines } from "../views/Logged/ResearchLines/ManageLines/ManageLines";
import { Commissions } from "../views/Logged/Commissions/Commissions";
import { ManageCommission } from "../views/Logged/Commissions/ManageCommission/ManageCommission";
import { Patents } from "../views/Logged/Patents/Patents";
import { ManagePatents } from "../views/Logged/Patents/ManagePatents/ManagePatents";
import { Offers } from "../views/Logged/Offers/Offers";
import { ManageOffer } from "../views/Logged/Offers/ManageOffer/ManageOffer";
import { Announcements } from "../views/Logged/Announcements/Announcements";
import { ManageAnnouncements } from "../views/Logged/Announcements/ManageAnnouncements/ManageAnnouncements";
import { Sliders } from "../views/Logged/Sliders/Sliders";
import { ManageSliders } from "../views/Logged/Sliders/ManageSliders/ManageSliders";
import { Resources } from "../views/Logged/Resources/Resources";
import { ManageResource } from "../views/Logged/Resources/ManageResources/ManageResource";
import { Users } from "../views/Logged/Users/Users";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { News } from "../views/Logged/News/News";
import { ManageNews } from "../views/Logged/News/ManageNews/ManageNews";
import { Intranets } from "../views/Logged/Intranet/Intranet";
import { ManageIntranet } from "../views/Logged/Intranet/ManageIntranet/ManageIntranet";
import { ManageFolder } from "../views/Logged/Intranet/ManageIntranet/ManageFolder";
import { ExMembers } from "../views/Logged/ExMembers/ExMembers";
import { ManageExMembers } from "../views/Logged/ExMembers/ManageExMembers/ManageExMembers";



const Admin_Gestion = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Users.all} component={Users} />
      <Route
        exact
        path={[ROUTES.Users.create, ROUTES.Users.update]}
        component={ManageProfile}
      />

      <Route exact path={ROUTES.Lines.all} component={ResearchLines} />
      <Route
        exact
        path={[ROUTES.Lines.create, ROUTES.Lines.update]}
        component={ManageLines}
      />
      <Route exact path={ROUTES.Commissions.all} component={Commissions} />
      <Route
        exact
        path={[ROUTES.Commissions.create, ROUTES.Commissions.update]}
        component={ManageCommission}
      />
      <Route exact path={ROUTES.Patents.all} component={Patents} />
      <Route
        exact
        path={[ROUTES.Patents.create, ROUTES.Patents.update]}
        component={ManagePatents}
      />
      <Route exact path={ROUTES.Offers.all} component={Offers} />
      <Route
        exact
        path={[ROUTES.Offers.create, ROUTES.Offers.update]}
        component={ManageOffer}
      />
      <Route exact path={ROUTES.Announcements.all} component={Announcements} />
      <Route
        exact
        path={[ROUTES.Announcements.create, ROUTES.Announcements.update]}
        component={ManageAnnouncements}
      />
      <Route exact path={ROUTES.Sliders.all} component={Sliders} />
      <Route
        exact
        path={[ROUTES.Sliders.create, ROUTES.Sliders.update]}
        component={ManageSliders}
      />
      <Route exact path={ROUTES.Resource.all} component={Resources} />
      <Route
        exact
        path={[ROUTES.Resource.create, ROUTES.Resource.update]}
        component={ManageResource}
      />
      <Route exact path={ROUTES.Intranet.all} component={Intranets} />
      <Route
        exact
        path={[ROUTES.Intranet.create, ROUTES.Intranet.update]}
        component={ManageIntranet}
      />
      <Route exact path={ROUTES.Projects.all} component={Projects} />
      <Route
        exact
        path={[ROUTES.Projects.create, ROUTES.Projects.update]}
        component={ManageProject}
      />
      <Route exact path={ROUTES.News.all} component={News} />
      <Route
        exact
        path={[ROUTES.News.create, ROUTES.News.update]}
        component={ManageNews}
      />

      <Route
        exact
        path={[ROUTES.INTRANETFOLDERS.create, ROUTES.INTRANETFOLDERS.update]}
        component={ManageFolder}
      />
          <Route exact path={ROUTES.ExMembers.all} component={ExMembers} />
          <Route
            exact
            path={[ROUTES.ExMembers.create, ROUTES.ExMembers.update]}
            component={ManageExMembers}
          />
    </>
  );
};

export default Admin_Gestion;
