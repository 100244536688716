// prettier-ignore
export const validate = ({ name, surname1, orcid, descriptionEs, descriptionEn, descriptionVa,}: any) => {
    let aux = "";
    if (!name) aux = "Por favor rellene el nombre .";
    if (!surname1) aux = 'Por favor rellene el apellido';
    if (!orcid) aux = 'Por favor rellene orcid.';
    if (!descriptionEs) aux = "Por favor rellene la descripción en español.";
    if (!descriptionEn) aux = 'Por favor rellene la descripción en ingles.';
    if (!descriptionVa) aux = 'Por favor rellene la descripción en valenciano.';


    
 

    return aux;
  };

