/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import { getAll, getById } from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';

interface IExMemberShort {
	id: string;
	name: string;
	surname1: string;
	surname2:string
}

export const ExMembers: FC = () => {
	const history = useHistory();

	let { staffId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

	const [isLoading, setIsLoading] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { EX_MEMBERS, INVESTIGATORS } = paths;
	const [exMembers, setExMembers] = useState<any>([]);
	const [search, setSearch] = useState('');
	const [filteredExMembers, setFilteredExMembers] = useState<IExMemberShort[]>([]);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		try {
			isMountedRef.current && loadExMembers();
		} catch (error) {
			console.log(error);
			console.log('HTTP call cancelled');
		}
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const loadExMembers = async () => {
		try {
			if (isAdmin) {
				const response = await getAll(EX_MEMBERS, source);
				
				if (isMountedRef.current) {
					filterExMembers(response);
				}
				
			} else if (!isAdmin) {
				let id = staffId.toString();
				const investigator = await getById(INVESTIGATORS, id, source);
				if (isMountedRef.current) {
					filterLineByInvestigator(investigator);
				}
			}
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterExMembers = (rawExMembers: any[]) => {
		let exMembers: IExMemberShort[] = rawExMembers.map(
			({ id, name, surname1, surname2 }: IExMemberShort) => ({
				id,
				name,
				surname1,
				surname2
			})
		);
		setExMembers(exMembers);
		setFilteredExMembers(exMembers);
	};

	const filterLineByInvestigator = (rawExMembers: any) => {
		// console.log(rawGroups.investigationGroup);
		if (!!rawExMembers.investigationLine) {
			let exMembers: IExMemberShort[] = [
        {
          id: rawExMembers?.investigationLine?.id,
          name: rawExMembers?.investigationLine?.name,
          surname1: rawExMembers?.investigationLine?.surname1,
          surname2: rawExMembers?.investigationLine?.surname2,
        },
      ];
			setExMembers(exMembers);
			setFilteredExMembers(exMembers);
		}
	};

	const handleSearch = (value: any) => {
		setSearch(value);
		setFilteredExMembers(
			exMembers.filter(({ name }: any) =>
				name.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setFilteredExMembers(exMembers);
	};

	return (
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h3>Ex miembros</h3>
          <div>
            {isAdmin && (
              <button
                className="baseBtn"
                onClick={() => history.push(`${ROUTES.ExMembers.create}`)}
              >
                + Añadir
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
      <div className="row mt-4">
        <Form.Group as={Col}>
          <InputGroup className="shadow">
            <InputGroup.Prepend>
              <InputGroup.Text className="inputGroup ">
                <FontAwesomeIcon color="#0073B5" icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Buscar..."
              className="form-control"
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="row">
        <div className="col-12 ">
          <table className="table table-hover">
            <tbody>
              {filteredExMembers?.map(
                (
                  { id, name, surname1, surname2 }: IExMemberShort,
                  index: number
                ) => (
                  <tr
                    key={index}
                    className="pointer"
                    onClick={() =>
                      history.push(`${ROUTES.ExMembers.all}/${id}`)
                    }
                  >
                    <td>
                      {`${name}`}, {`${surname1}`} {`${surname2}`}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border mt-5" role="status" />
            </div>
          )}

          {!filteredExMembers.length && !isLoading && isAdmin && (
            <div className="d-flex justify-content-center mb-3">
              <h5>-No existen ex miembros-</h5>
            </div>
          )}

          {!filteredExMembers.length && !isLoading && !isAdmin && (
            <div className="d-flex justify-content-center mb-3">
              <h5>-No pertenece a ningún grupo-</h5>
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};
