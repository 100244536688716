import { create } from "domain";
import { update } from "./requests/dynamicReq";


export const ROUTES = {
  Login: "/",
  ManageProfile: "/perfil",
  Users: {
    all: "/usuarios",
    update: "/usuarios/:id",
    create: "/usuarios/crear",
  },
  Groups: {
    all: "/grupos",
    update: "/grupos/:id",
    create: "/grupos/crear",
  },
  Projects: {
    all: "/proyectos",
    update: "/proyectos/:id",
    create: "/proyectos/crear",
  },
  Subjects: {
    all: "/asignaturas",
    update: "/asignaturas/:id",
    create: "/asignaturas/crear",
  },
  Publications: {
    all: "/publicaciones",
    update: "/publicaciones/:id",
    create: "/publicaciones/crear",
  },
  News: {
    all: "/noticias",
    update: "/noticias/:id",
    create: "/noticias/crear",
  },
  Degree: {
    all: "/formacion",
    update: "/formacion/:id",
    create: "/formacion/crear",
  },
  TeachingResources: {
    all: "/teachingResources",
    update: "/teachingResources/:id",
    create: "/teachingResources/crear",
  },
  Archive: {
    all: "/archivo",
    update: "",
    create: "",
  },
  Commissions: {
    all: "/commissions",
    update: "/commissions/:id",
    create: "/commissions/crear",
  },
  Lines: {
    all: "/transversalActions",
    update: "/transversalActions/:id",
    create: "/transversalActions/crear",
  },
  Patents: {
    all: "/patents",
    update: "/patents/:id",
    create: "/patents/crear",
  },
  Offers: {
    all: "/offers",
    update: "/offers/:id",
    create: "/offers/crear",
  },
  Announcements: {
    all: "/announcements",
    update: "/announcements/:id",
    create: "/announcements/crear",
  },
  Sliders: {
    all: "/sliders",
    update: "/sliders/:id",
    create: "sliders/crear",
  },
  Resource: {
    all: "/resource",
    update: "/resource/:id",
    create: "resource/crear",
  },
  Intranet: {
    all: "/intranets",
    update: "/intranets/:id",
    create: "intranets/crear",
  },
  INTRANETFOLDERS: {
    all: "/intranet-folders",
    update: "/intranet-folders/:id",
    create: "intranet-folders/crear",
  },
  RESOURCEFOLDERS: {
    all: "/resource-folders",
    update: "/resource-folders/:id",
    create: "resource-folders/crear",
  },
  ExMembers: {
    all: "/ex-members",
    update: "/ex-members/:id",
    create: "ex-members/crear",
  },
};
